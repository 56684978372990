<template>
  <div class="home">
    <MyheadCompnent />
    <div style="width: 100%; height: 780px; position: relative;">
      <div class="large-logo">
        <img src="@/assets/img/logoHome.png" alt="PMhub" style="width: 600px;">
      </div>
      <!-- <MetaboliteBrowseCompnentSearchHome class="content-search" style="border: rgb(229, 107, 7);">
      </MetaboliteBrowseCompnentSearchHome> -->
      <div class="grid-content bg-purple content-search">
        <div style="margin-top: 15px">
          <el-input placeholder="search items" v-model="searchItem" class="input-with-select"
            @keyup.enter.native="searchHome" size="large">
            <!-- <template #prepend>
              <div>
                <select v-model="searchType" class="select-style">
                  <option value="Metabolite">Metabolite</option>
                  <option value="Feature">Feature</option>
                </select>
              </div>
            </template> -->
            <template #append>
              <el-button icon="el-icon-search" @click="searchHome"></el-button>
            </template>
          </el-input>
        </div>
      </div>

      <div class="content-search" style="top: 50%" v-show="searchType=='Metabolite'">
        <li style="cursor: default;">Try:</li>
        <li class="liclass" @click="search('name','L-Leucine')">L-Leucine</li>
        <li class="liclass" @click="search('molecular','C6H13NO2')">C6H13NO2</li>
        <li class="liclass" @click="search('smiles','CC(C)CC(N)C(=O)O')">CC(C)CC(N)C(=O)O</li>
        <li class="liclass" @click="search('inchi','InChI=1S/C6H13NO2/c1-4(2)3-5(7)6(8)9/h4-5H,3,7H2,1-2H3,(H,8,9)')">
          InChI=1S/C6H13NO2/c1-4(2)3-5(7)6(8)9/h4-5H,3,7H2,1-2H3,(H,8,9)</li>
      </div>
      <div class="content-search" style="top: 50%" v-show="searchType=='Feature'">
        <!-- <div class="margin1">
          <div class="margin">
            <p>Molecular weight</p>
            <input v-model="searchThreshould.molecularWT" class="threshold-input">
          </div>
        </div>
        <div class="margin1">
          <div class="margin">
            <p>Q1</p>
            <input v-model="searchThreshould.q1" class="threshold-input">
          </div>
        </div> -->
        <div position="left">
          <div>
            <li style="cursor: default;">Try:</li>
          </div>
          <div>
            <li class="liclass" @click="search2('weight','330')">330</li>
            <li class="liclass" @click="search2('name','Tricin')">Tricin</li>
            <li class="liclass" @click="search2('molecular','C17H14O7')">C17H14O7</li>
            <li class="liclass" @click="search2('smiles','COc1cc(-c2cc(=O)c3c(O)cc(O)cc3o2)cc(OC)c1O')">
              COc1cc(-c2cc(=O)c3c(O)cc(O)cc3o2)cc(OC)c1O</li>
            <li class="liclass"
              @click="search2('inchi','InChI=1S/C17H14O7/c1-22-14-3-8(4-15(23-2)17(14)21)12-7-11(20)16-10(19)5-9(18)6-13(16)24-12/h3-7,18-19,21H,1-2H3')">
              InChI=1S/C17H14O7/c1-22-14-3-8(4-15(23-2)17(14)21)12-7-11(20)16-10(19)5-9(18)6-13(16)24-12/h3-7,18-19,21H,1-2H3
            </li>
          </div>
        </div>
      </div>
      <div class="content-search2" style="top: 70%">
        <div class="librarycount">Compounds:</div>
        <div class="librarycount2">188,938</div>
        <!-- <div class="librarycount">Positive MS/MS:</div>
        <div class="librarycount2">237,156</div>
        <div class="librarycount">Negative MS/MS:</div>
        <div class="librarycount2">99,688</div> -->
        <div class="librarycount">Positive MS/MS:</div>
        <div class="librarycount2">193,910</div>
        <div class="librarycount">Negative MS/MS:</div>
        <div class="librarycount2">85,840</div>
      </div>
    </div>

    <div style="text-align: center">
      <el-row>
        <el-col :span="2">
        </el-col>
        <el-col :span="18">
          <h3 align='center'>Welcome to PMhub</h3>
          <!-- <div id="example-1-output" class="col-sm-6"></div> -->
          <!-- <canvas id="draw2d2"></canvas> -->
          <!-- <img id="draw2d" width="300" height="300"> -->
          <!-- <canvas id="draw2d" width="300" height="300"></canvas> -->
          <!-- <p>The Crop Metabolite hub (CMhub) is a freely available electronic database containing detailed information
            about small molecule metabolites in nature. The database is designed to contain chemical data, mass spectra
            data, reaction data and GWAS data for several crops, such as Rice, Maize, Wheat and Tomato.</p> -->
          <p>The Plant Metabolite hub (PMhub) is a freely available electronic database containing detailed
            information
            about small molecule metabolites in nature. The database is designed to contain chemical data, mass
            spectra
            data, reaction data and GWAS data for several plants, such as Oryza sativa, Zea mays, Triticum aestivum,
            Lycopersicon esculentum.</p>
          <h3>
            Citing PMhub:
          </h3>
          <p>
            Tian Z, Hu X, Xu Y, Liu M, Liu H, Li D, Hu L, Wei G, Chen W. PMhub 1.0: a comprehensive plant metabolome
            database. Nucleic Acids Res. 2024 Jan 5;52(D1):D1579-D1587. <a
              href="https://pubmed.ncbi.nlm.nih.gov/37819039/">37819039</a>
          </p>
          <a href="https://beian.miit.gov.cn" target="_blank" textvalue="湘ICP备2022005878号">湘ICP备2022005878号</a>
          <div>
            <a href="https://www.revolvermaps.com/livestats/53rm05ryxm5/"><img
                src="//rf.revolvermaps.com/h/m/a/0/ff0000/128/0/53rm05ryxm5.png" width="180" height="90" alt="Map"
                style="border:0;"></a>
            <!-- <script type='text/javascript' id='clustrmaps' src='//cdn.clustrmaps.com/map_v2.js?cl=0e5c08&w=a&t=n&d=y4WTZRqWC-yoDl1HexRERmVSDXri9vCXCXgYSmWH4Eo&co=ffffff&cmo=ed7126&cmn=ff5353&ct=ff5353'></script> -->
            <a href='https://clustrmaps.com/site/1c2fw'  title='Visit tracker'><img src='//clustrmaps.com/map_v2.png?cl=0e5c08&w=a&t=n&d=y4WTZRqWC-yoDl1HexRERmVSDXri9vCXCXgYSmWH4Eo&co=ffffff&ct=ed7126'/></a>
          </div>

        </el-col>
        <el-col :span="4">
          <div style="text-align: center;">
            <img src="@/assets/img/home3.svg" width="90">
            <img src="@/assets/img/home1.svg" width="80">
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  // import initRDKitModule from '@rdkit/rdkit'
  import { ElMessage } from 'element-plus'
  // import initRDKitModule from '@/mymodules/rdkit/RDKit_minimal.js'

  // import MoleculeStructure from '@/mymodules/rdkit-js/examples/vue/src/components/MoleculeStructure.vue'
  import MetaboliteBrowseCompnentSearchHome from '@/components/Browse/MetaboliteBrowseCompnentSearchHome.vue'

  // import ChemDoodle from "@/mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import 'smiles-drawer'
  // // import common from '@/mymethods/common.js'
  // initRDKitModule().then(function (instance) {
  //   window.RDKitModule = instance;
  //   console.log("version: " + RDKitModule.version());
  // });
  export default {
    name: 'Home',
    data () {
      return {
        searchItem: '',
        searchThreshould: {
          'molecularWT': "",
          'molecularFormula': "",
          'q1': ""
        },
        selectItem: "metabolite",
        searchType: "Metabolite",
        searchable: true,
      }
    },
    methods: {
      search (selectItem, searchItem) {
        this.$router.push({ name: 'MetaboliteSearch', params: { selectItem: selectItem, searchItem: searchItem } })
      },
      search2 (selectItem, searchItem) {
        this.selectItem = selectItem
        this.searchItem = searchItem
      },
      searchHome () {
        this.searchable = true
        if (this.searchItem.search(/C\d*H\d*/i) === 0) {
          this.selectItem = 'molecular'
        } else if (this.searchItem.search(/^InChI=/) === 0) {
          this.selectItem = 'inchi'
        } else if (this.searchItem.search(/^MS\d{9}/i) === 0) {
          this.selectItem = 'metabolite'
        } else if (!isNaN(this.searchItem)) {
          this.selectItem = 'weight'
          var searchItemT = Number(this.searchItem)
          if (searchItemT == 0) {
            this.searchable = false
          } else {
            this.searchItem = Number(this.searchItem)
          }
        } else {
          this.selectItem = 'smiles'
        }
        if (this.searchType === "Metabolite" & this.searchable) {
          this.$router.push({ name: 'MetaboliteSearch', params: { selectItem: this.selectItem, searchItem: this.searchItem } })
        } else if (this.searchType === "Feature" & this.searchable) {

          var params = { selectItem: this.selectItem, searchItem: this.searchItem }
          this.$router.push({ name: 'FeatureBrowse2', params: params })
        }

      },
      hh () {
        var smiles = "CC(=O)Oc1ccccc1C(=O)O";
        var mol = RDKitModule.get_mol(smiles);
        var dest = document.getElementById("example-1-output");
        var svg = mol.get_svg();
        dest.innerHTML = "<div id='drawing'>" + svg + "</div>";
        // var smiles = "CC(=O)Oc1ccccc1C(=O)O";
        // var mol = RDKitModule.get_mol(smiles);
        // var canvas = document.getElementById("example-canvas2");
        // mol.draw_to_canvas(canvas, -1, -1);
      }
    },
    components: {
      MyheadCompnent,
      MetaboliteBrowseCompnentSearchHome,
      // MoleculeStructure
    },
    mounted () {
      // let options = {
      //   width: 500,
      //   height: 500,
      //   compactDrawing: false
      // }
      // let smilesDrawer = new SmilesDrawer.Drawer(options);
      // smilesDrawer.draw('N=C(O)CC', 'draw2d')
      // SmilesDrawer.parse('N=C(O)CC', function (tree) {
      //   smilesDrawer.draw(tree, 'draw2d', 'light', false);
      // }, function (err) {
      //   console.log(err);
      // })

      // console.log(RDKitModule)
      // var smiles = "CC(=O)Oc1ccccc1C(=O)O";
      // var mol = RDKitModule.get_mol(smiles);
      // var dest = document.getElementById("example-1-output");
      // var svg = mol.get_svg();
      // dest.outerHTML = "<div id='drawing'>" + svg + "</div>";

      // onRuntimeInitialized: initRDKitModule().then(function (instance) {
      //   RDKitModule = instance;
      //   console.log('version: ' + RDKitModule.version());
      // })
      // var smiles = "CC(=O)Oc1ccccc1C(=O)O";
      // var mol = RDKitModule.get_mol(smiles);
      // var dest = document.getElementById("example-1-output");
      // var svg = mol.get_svg();
      // dest.outerHTML = "<div id='drawing'>" + svg + "</div>";
      // //noodles

    }
  }
</script>

<style scoped lang="scss">
  .large-logo {
    margin: 100px;
    text-align: center
  }

  .content-search {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    height: 160px;
    width: 900px;
    text-align: center;
  }

  .content-search2 {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  li {
    float: left;
    padding: 2em;
    list-style: None;
    cursor: pointer;
    font-size: 12px;
  }

  .librarycount {
    padding: 1em;
    list-style: None;
    font-size: 18px;
    display: inline-block;
  }

  .librarycount2 {
    padding: 1em;
    list-style: None;
    font-size: 22px;
    display: inline-block;
  }

  li.liclass:hover {
    color: rgb(229, 107, 7);
  }

  .el-select .el-input {
    width: 300px;
  }

  .el-input {
    --el-input-border-radius: 30px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: rgb(229, 107, 7);
  }

  .el-input {
    :deep(.el-input__inner) {
      height: 60px;
      font-size: 24px;
    }

    :deep(.el-input-group__append) {
      color: rgb(229, 107, 7);
    }

    :deep(.el-input__suffix) {
      top: 20%;
    }
  }

  .select-style {
    border: none;
    background-color: transparent;
    font-size: 26px;
    /* 修改字体大小 */
    font-family: Arial, sans-serif;
    color: #606266;
    outline: none;
    padding: 10px;
  }

  .margin1 {
    display: flex;
    align-content: space-around;
    margin-bottom: 20px;
    display: inline-block
  }

  .margin {
    margin-right: 40px;
  }

  .threshold-input {
    border-radius: 10px;
    height: 20px;
    width: 100px
  }
</style>