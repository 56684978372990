import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/metabolitebrowse',
    name: 'MetaboliteBrowse',
    component: () => import('../views/MetaboliteBrowse.vue'),
    meta: {
      keepAlive: true    //需要缓存
    },
  }, {
    path: '/featurebrowse',
    name: 'FeatureBrowse',
    component: () => import('../views/FeatureBrowse.vue'),
    meta: {
      keepAlive: true    //需要缓存
    },
  }, {
    path: '/featurebrowse2',
    name: 'FeatureBrowse2',
    component: () => import('../views/FeatureBrowse2.vue'),

  }, {
    path: '/pathwaybrowse',
    name: 'PathwayBrowse',
    component: () => import('../views/PathwayBrowse.vue'),
    meta: {
      keepAlive: true    //需要缓存
    },
  }, {
    path: '/locsearch',
    name: 'LocSearch',
    component: () => import('../views/LocSearch.vue'),
    meta: {
      keepAlive: true    //需要缓存
    },
  },
  {
    path: '/metabolite2locsearch',
    name: 'Metabolite2LocSearch',
    component: () => import('../views/Metabolite2LocSearch.vue'),
    meta: {
      keepAlive: true    //需要缓存
    },
  },
  {
    path: '/networkdetail/:searchItem',
    name: 'NetworkDetail',
    component: () => import('../views/NetworkDetail.vue')
  },
  {
    // path: '/metabolitesearch/:selectItem/:searchItem',
    path: '/metabolitesearch',
    name: 'MetaboliteSearch',
    component: () => import('../views/MetaboliteSearch.vue'),
  },
  {
    path: '/metabolitedetail/:searchItem',
    name: 'MetaboliteDetail',
    component: () => import('../views/MetaboliteDetail.vue')
  },
  {
    path: '/featuredetail/:searchItem',
    name: 'FeatureDetail',
    component: () => import('../views/FeatureDetail.vue')
  },
  {
    path: '/featuredetail2/:searchItem',
    name: 'FeatureDetail2',
    component: () => import('../views/FeatureDetail2.vue')
  },
  {
    path: '/msdetail/:msID/:metabolite',
    name: 'MsDetail',
    component: () => import('../views/MsDetail.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test.vue')
  },
  {
    path: '/search/MetaboliteIdentification',
    name: 'MetaboliteIdentification',
    component: () => import('../views/MetaboliteIdentification.vue'),
  },
  {
    path: '/search/MetaboliteIdentification/diy',
    name: 'MetaboliteIdentification_diy',
    component: () => import('../views/MetaboliteIdentification_diy.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    component: () => import('../views/Tutorial.vue')
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue')
  },
  {
    path: '/structuredraw',
    name: 'StructureDraw',
    component: () => import('../views/StructureDraw.vue')
  },
  {
    path: '/downloadlibrary',
    name: 'DownloadLibrary',
    component: () => import('../views/DownloadLibrary.vue')
  },
  {
    path: '/featurehierarchy',
    name: 'FeatureHierarchy',
    component: () => import('../views/FeatureHierarchy.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'hash',
  routes
})

export default router
